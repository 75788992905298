$color-title: #111111;
$color-body: #555555;
$color-grey: #888888;
$color-background: #f7f7f7;
$color-white: #ffffff;
$color-danger: #dc3545;
$color-warning: #ff9c07;
$color-success: #52c278;
$color-info: #17a2b8;
$color-primary: #fdcf09;
$color-secondary: #001533;
$color-accent: #29b9a0;
$color-border: #e1e1e1;
$color-yellow: #eec743;
$color-purple: #800080;
$disabled-color: #000;
$corner-radius: 25px;
$box-shadow: 0 0 10px rgb(0 0 0 / 15%);

$header-font: 'Quicksand', sans-serif;
$body-font: 'Open Sans', sans-serif;
